<template>
  <div>
    <!-- INFORMATION -->
    <h3>{{ $t('onboarding.videoIdentInformation.biometricData.title') }}</h3>
    <p class="mt-1">{{ $t('onboarding.videoIdentInformation.biometricData.desc') }}</p>
    <!-- ACCOUNT SECURITY -->
    <v-alert
      text
      color="info"
      class="rounded-xl"
    >
      <h3>{{ $t('onboarding.videoIdentInformation.accountSecurity.title') }}</h3>
      <p>{{ $t('onboarding.videoIdentInformation.accountSecurity.desc') }}</p>
    </v-alert>
    <!-- CHECKBOXES -->
    <v-form
      v-model="isFormValid"
    >
      <v-card
        color=backgroundLight
        rounded="xl"
      >
        <v-container>
          <v-checkbox
            v-model="agreeResponsibility"
            :rules="rules.required"
            :label="$t('onboarding.videoIdentInformation.checkbox.responsibility')"
            color="primary"
          ></v-checkbox>
        </v-container>
      </v-card>
      <br>
      <v-card
        color=backgroundLight
        rounded="xl"
      >
        <v-container>
          <v-checkbox
            v-model="agreeProcessing"
            :rules="rules.required"
            :label="$t('onboarding.videoIdentInformation.checkbox.processing')"
            color="primary"
          ></v-checkbox>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { required } from '@/utils/validators';

export default {
  name: 'VideoIdentInformation',
  data() {
    return {
      rules: {
        required,
      },
    };
  },
  computed: {
    ...mapFields('onboarding', [
      'isFormValid',
      'agreeResponsibility',
      'agreeProcessing',
    ]),
  },
};
</script>

<style lang="scss" scoped>

</style>
